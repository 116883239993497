import { useState } from 'react';
import { CALENDLY_LINK, PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from '../../constants';
import Popup from '../Popup';
import brochureThumbnail from '../../public/images/brochure_thumbnail.jpg';
import { useRouter } from 'next/router';
import mailjetService, { interestedLeadsListId } from '../../services/mailjetService';
import senderService from '../../services/senderService';
import { SENDER_GROUPS } from '../../constants/sender';

export default function WatchVideoDemo({ classNameBtn }) {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const router = useRouter();

	let initialInputs = {
		name: '',
		email: '',
	};

	const [inputs, setInputs] = useState(initialInputs);

	const handleInputChange = (e) => {
		e.persist();

		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const clearState = () => {
		setLoading(false);
		setInputs({ ...initialInputs });
	};

	const handleSubmit = async (e) => {
		setLoading(true);

		try {
			e.preventDefault();
			fbq('track', 'Schedule');
			gtag_report_conversion(CALENDLY_LINK);

			// will drop the user information into Interested Leads contact list in mailjet
			await mailjetService.post(`/contactslist/${interestedLeadsListId}/managecontact`, {
				Name: inputs.name,
				Properties: {
					name: inputs.name,
				},
				Action: 'addnoforce',
				Email: inputs.email,
			});
			await senderService.post('/subscribers', {
				email: inputs.email,
				firstname: inputs.name,
				groups: [SENDER_GROUPS.VIRTUAL_DEMO_SIGN_UPS],
			});
		} catch (err) {
			console.error('🚀 ~ file: download_brochure.js ~ line 53 ~ handleSubmit ~ err', err);
		} finally {
			router.push('/features/virtual_demo');
		}
	};

	return (
		<>
			<button className={classNameBtn} onClick={() => setVisible(!visible)}>
				Watch Demo
			</button>
			<Popup visible={visible} onCancel={() => setVisible(!visible)} afterClose={() => clearState()}>
				<div className="brochure">
					<div className="brochure__left">
						<img src={brochureThumbnail} alt="Brochure thumbnail" />
					</div>

					<div className="brochure__right">
						<div className="brochure__title-wrapper">
							<h2 className="brochure__title">
								Thanks for your interest in <span>Facility</span>Bot
							</h2>
							<h3 className="brochure__subtitle">
								Please feel free to schedule an in-person demo if you need more information after watching the virtual
								demo
							</h3>
						</div>
						<form className="brochure__form" onSubmit={handleSubmit}>
							<div className="brochure__form-field">
								<label htmlFor="name">Name</label>
								<input
									type="text"
									id="name"
									name="name"
									required
									placeholder="Name"
									value={inputs.name}
									onChange={handleInputChange}
								/>
							</div>

							<div className="brochure__form-field">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									id="email"
									name="email"
									required
									autoFocus
									placeholder="Email Address"
									value={inputs.email}
									onChange={handleInputChange}
								/>
							</div>

							<div className="brochure__form-field">
								<button type="submit" className="btn btn--cta-register">
									{loading && <i className="fa fa-spinner fa-spin"></i>} Watch Virtual Demo
								</button>
							</div>

							<div className="brochure__form-field">
								<span className="brochure__policy">
									By submitting this form, you agree to our{' '}
									<a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
										Privacy Policy
									</a>{' '}
									and{' '}
									<a href={TERMS_AND_CONDITION_LINK} target="_blank" rel="noreferrer">
										Terms & Conditions
									</a>
								</span>
							</div>
						</form>
					</div>
				</div>
			</Popup>
		</>
	);
}
